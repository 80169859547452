import { styled } from "utils/emotion";
import media from "utils/mediaqueries";
import { colors } from "components/Layout/variables";
import Button from "components/Button";
import Image from "components/Image";

export const StyledSectionFooter = styled("section")(
  {
    position: "relative",
    minHeight: "none",
    zIndex: 1,
    padding: "0px 0 0 0"
  },
  props => ({
    backgroundColor: props.theme.backgroundPrimary
  })
);

export const StyledImageText = styled("div")({
  paddingTop: 60,

  [media("tablet")]: {
    maxWidth: 400,
    marginLeft: "auto"
  }
});

export const StyledFooterNavWrapper = styled("div")({}, props => ({
  paddingBottom: props.theme.space,

  [media("tabletFluid")]: {
    minHeight: 550,
    paddingBottom: props.theme.spaceFive
  }
}));

export const StyledFooterImageWrapper = styled("div")({}, props => ({
  paddingTop: props.theme.space,
  paddingBottom: 0,

  [media("desktop")]: {
    paddingRight: props.theme.spaceDouble
  }
}));

export const StyledImage = styled(Image)({}, props => ({
  marginTop: 25,
  paddingRight: props.theme.spaceTriple,

  [media(0, "mobile")]: {
    marginBottom: -3,
    borderBottom: `3px solid ${colors.green}`,
    padding: 0
  },

  [media("tablet")]: {
    img: {
      objectPosition: "100% 100% !important"
    }
  },

  [media(0, "tablet")]: {
    img: {
      objectPosition: "50% 50% !important"
    }
  }
}));

export const StyledButtonLink = styled(Button)({
  marginTop: 0,
  marginBottom: 0
});

export const StyledButtonHome = styled("div")({}, props => ({
  paddingBottom: 0,

  [media("tabletFluid")]: {
    marginTop: props.theme.spaceFive
  },

  [media("laptop")]: {
    marginTop: props.theme.spaceDouble
  }
}));

export const StyledButtonGroup = styled("div")({
  padding: "20px 0"
});
