import React from "react";
import { graphql } from "gatsby";
import Headline from "components/Headline";
import Text from "components/Text";
import Button from "components/Button";
import Grid from "components/Grid";
import GridItem from "components/GridItem";
import NavigationSectionFooter from "components/NavigationSectionFooter";

import {
  StyledSectionFooter,
  StyledImageText,
  StyledFooterNavWrapper,
  StyledFooterImageWrapper,
  StyledImage,
  StyledButtonLink,
  StyledButtonHome,
  StyledButtonGroup
} from "./styles";

const FooterSection = props => {
  const {
    footerSectionNavigationItems,
    imageText,
    imageHeadline,
    footerSectionLink,
    footerSectionLinkAndroid,
    image,
    currentBaseUrl,
    backButton
  } = props;

  return (
    <StyledSectionFooter>
      <Grid wrapReverse>
        <GridItem tabletFluid={1 / 2}>
          <Grid bottom>
            <GridItem>
              {image && (
                <StyledImage
                  width={"738px"}
                  maxWidth={"738px"}
                  aspectRatio={4 / 3}
                  fluid={image[0]}
                  objectFit="contain"
                />
              )}
            </GridItem>
          </Grid>
        </GridItem>
        <GridItem tabletFluid={1 / 2}>
          <Grid between bottom>
            <GridItem bottom mobile={0}>
              <StyledFooterImageWrapper>
                <StyledImageText>
                  {imageHeadline && (
                    <Headline element="h4" uppercase heavy>
                      {imageHeadline}
                    </Headline>
                  )}
                  {imageText && <Text>{imageText}</Text>}
                  <StyledButtonGroup>
                    {footerSectionLink && (
                      <StyledButtonLink
                        to={
                          footerSectionLink?.entry?.fullUri ||
                          footerSectionLink?.url
                        }
                        icon="arrowRight"
                        stripped
                        uppercase
                      >
                        {footerSectionLink?.text}
                      </StyledButtonLink>
                    )}
                    {footerSectionLinkAndroid && (
                      <StyledButtonLink
                        to={
                          footerSectionLinkAndroid?.entry?.fullUri ||
                          footerSectionLinkAndroid?.url
                        }
                        icon="arrowRight"
                        stripped
                        uppercase
                      >
                        {footerSectionLinkAndroid?.text}
                      </StyledButtonLink>
                    )}
                  </StyledButtonGroup>
                </StyledImageText>
              </StyledFooterImageWrapper>
            </GridItem>
          </Grid>
        </GridItem>
      </Grid>
    </StyledSectionFooter>
  );
};

export default FooterSection;

export const query = graphql`
  fragment GlobalFooterSectionQuery on Craft_GlobalsSet {
    footerSection {
      footerSectionNavigationItems: footerSectionNavigation {
        subline: footerSectionNavigationItemSubline
        headline: footerSectionNavigationItemHeadline
        entry: footerSectionNavigationItemEntry {
          id
          fullUri
        }
      }
      imageHeadline: footerSectionImageHeadline
      imageText: footerSectionImageText
      backButton: footerSectionBackHomeButton
      image: footerSectionImage {
        ...CraftImageFluid
      }

      footerSectionLink {
        url
        text
        entry {
          title
          fullUri
        }
      }

      footerSectionLinkAndroid {
        url
        text
        entry {
          title
          fullUri
        }
      }
    }
  }
`;
